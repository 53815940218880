/* eslint-disable dot-notation */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable no-magic-numbers */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable max-len */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { CHECKOUT, MY_ACCOUNT } from 'Component/Header/Header.config';
import ConfigQuery from 'Query/Config.query';
import MyAccountQuery from 'Query/MyAccount.query';
import {
    getAddGiftCard,
    getGiftCardDetails,
    getRemoveGiftCard,
    updateCustomerDetails,
    updateCustomerPasswordForgotStatus,
    updateCustomerPasswordResetStatus,
    updateCustomerSignInStatus,
    updateIsLoading
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { ORDERS } from 'Store/Order/Order.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { clearComparedProducts } from 'Store/ProductCompare/ProductCompare.action';
import {
    deleteAuthorizationToken,
    isSignedIn,
    setAuthorizationToken,
    setIsAdminLoggedInAsCustomer
} from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { deleteGuestQuoteId, getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { removeUid } from 'Util/Compare';
import { getCurrency, setCurrency } from 'Util/Currency';
import { md5 } from 'Util/Helper';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import {
    executePost, fetchMutation, fetchQuery, getErrorMessage
} from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
);

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
);

export const CUSTOMER = 'customer';

export const ONE_MONTH_IN_SECONDS = 2628000;

/**
 * My account actions
 * @class MyAccount
 * @namespace Pwa/Store/MyAccount/Dispatcher/MyAccountDispatcher */
export class MyAccountDispatcher {
    forceLogoutRedirectPages = [
        CHECKOUT,
        MY_ACCOUNT
    ];

    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};
        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        return executePost(prepareQuery([query])).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/executePost/then */
            ({ customer }) => {
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                setTimeout(() => {
                    localStorage.setItem('isLoadingAccountInfo', '0');
                }, 2000);
            },
            /** @namespace Pwa/Store/MyAccount/Dispatcher/executePost/then */
            (error) => {
                if (getErrorMessage(error).includes('current customer isn') || getErrorMessage(error).includes('t authorized')) {
                    this.logout(true, dispatch);
                } else {
                    dispatch(showNotification('error', getErrorMessage(error)));
                }
            }
        );
    }

    logout(authTokenExpired = false, dispatch) {
        localStorage.setItem('email_id', '');
        // resetting the admin login as customer to false
        setIsAdminLoggedInAsCustomer(false);
        if (authTokenExpired) {
            deleteAuthorizationToken();
            // dispatch(showNotification('error', __('Your session is over, you are logged out!')));
        } else {
            deleteAuthorizationToken();
            dispatch(showNotification('success', __('You are successfully logged out!')));
        }
        deleteGuestQuoteId();
        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        removeUid();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));
        const currentCurrencyCode = getCurrency();
        if (window.storeConfig.baseName.includes('en/uk') && currentCurrencyCode === 'EUR') {
            return fetchMutation(ConfigQuery.getSaveSelectedCurrencyMutation(
                'GBP'
            )).then(
                setCurrency('GBP'),
                window.location.reload()
            );
        }
        this.cleanUpSession(dispatch);
        setTimeout(() => {
            // your code to be executed after 1 second
            this.handleForceRedirectToLoginPage();
        }, 2000);
    }

    cleanUpSession = (dispatch) => {
        // After logout cart, wishlist and compared product list is always empty.
        // There is no need to fetch it from the backend.
        CartDispatcher.then(
            ({ default: dispatcher }) => {
                dispatcher.resetGuestCart(dispatch);
                dispatcher.createGuestEmptyCart(dispatch);
            }
        );
        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetWishlist(dispatch)
        );

        dispatch(clearComparedProducts());
        dispatch(updateCustomerDetails({}));
    };

    /**
     * Forgot password action
     * @param {{email: String}} [options={}]
     * @returns {Promise<{status: String}>} Reset password token
     * @memberof MyAccountDispatcher
     */
    forgotPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getForgotPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            () => dispatch(updateCustomerPasswordForgotStatus()),
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => dispatch(showNotification('error', getErrorMessage(error)))
        );
    }

    /**
     * Reset password action
     * @param {{token: String, password: String, password_confirmation: String}} [options={}]
     * @returns {Promise<{status: String}>} Reset password token
     * @memberof MyAccountDispatcher
     */
    resetPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getResetPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            ({ s_resetPassword: { status } }) => dispatch(updateCustomerPasswordResetStatus(status)),
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (errors) => dispatch(updateCustomerPasswordResetStatus('error', getErrorMessage(errors)))
        );
    }

    /**
     * Create account action
     * @param {{customer: Object, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    createAccount(options = {}, dispatch) {
        const { customer: { email }, password } = options;
        options.customer['privacyConsentSelection'] = [];
        const mutation = MyAccountQuery.getCreateAccountMutation(options);
        dispatch(updateIsLoading(true));
        localStorage.setItem('email_id', md5(email));
        return fetchMutation(mutation).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (data) => {
                const { createCustomer: { customer } } = data;
                const { confirmation_required } = customer;

                if (confirmation_required) {
                    dispatch(updateIsLoading(false));

                    return 2;
                }

                return this.signIn({ email, password }, dispatch);
            },

            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => {
                dispatch(updateIsLoading(false));
                dispatch(showNotification('error', getErrorMessage(error)));
                Promise.reject();

                return false;
            }
        );
    }

    /**
     * Confirm account action
     * @param {{key: String, email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    confirmAccount(options = {}, dispatch) {
        const mutation = MyAccountQuery.getConfirmAccountMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            () => dispatch(showNotification('success', __('Your account is confirmed!'))),
            /** @namespace Pwa/Store/MyAccount/Dispatcher/fetchMutation/then */
            (error) => dispatch(
                showNotification(
                    'error',
                    getErrorMessage(error, __('Something went wrong! Please, try again!'))
                )
            )
        );
    }

    /**
     * Sign in action
     * @param {{email: String, password: String}} [options={}]
     * @memberof MyAccountDispatcher
     */
    async signIn(options = { }, dispatch) {
        const { email } = options;
        const mutation = MyAccountQuery.getSignInMutation(options);

        const result = await fetchMutation(mutation);
        const { generateCustomerToken: { token } } = result;

        setAuthorizationToken(token);
        // setting isAdminLoggedInAsCustomer as false
        setIsAdminLoggedInAsCustomer(false);

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        // ProductCompareDispatcher.then(
        //     ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        // );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));
        localStorage.setItem('email_id', md5(email));
        localStorage.setItem('email', email);

        return true;
    }

    async signInWithToken(options = {}, dispatch) {
        const mutation = MyAccountQuery.getSignInWithTokenMutation(options);

        const result = await fetchQuery(mutation);
        const { Getcustomertokenbytoken: { token } } = result;

        setAuthorizationToken(token);
        // admin logged in as customer here
        setIsAdminLoggedInAsCustomer(true);
        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();
        // if customer is authorized, `createEmptyCart` mutation returns customer cart token
        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            // merge guest cart id and customer cart id using magento capabilities
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch);

        WishlistDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateInitialWishlistData(dispatch)
        );

        // ProductCompareDispatcher.then(
        //     ({ default: dispatcher }) => dispatcher.assignCompareList(dispatch)
        // );

        await this.requestCustomerData(dispatch);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You are successfully logged in!')));

        return true;
    }

    handleForceRedirectToLoginPage() {
        history.push({ pathname: '/account/login' });
        window.location.reload(true);
        /*
        const { location: { pathname = '' } = {} } = history;
        const doRedirect = this.forceLogoutRedirectPages.reduce((result, urlPart) => {
            if (pathname.includes(urlPart)) {
                return true;
            }

            return result;
        }, false);

        if (doRedirect) {
            history.push({ pathname: '/account/login' });
        }
        */
    }

    handleCustomerDataOnInit(dispatch) {
        if (isSignedIn()) {
            return;
        }

        BrowserDatabase.deleteItem(ORDERS);
        BrowserDatabase.deleteItem(CUSTOMER);
        CartDispatcher.then(
            ({ default: dispatcher }) => dispatcher.resetGuestCart(dispatch)
        );
    }

    async getGiftCardDetailsDispatcher(_options = {}, dispatch) {
        try {
            dispatch(updateIsLoading(true));
            const query = MyAccountQuery.getassignAmGiftCard(_options);
            const result = fetchQuery(query);
            result.then(
                /** @namespace Pwa/Store/MyAccount/Dispatcher/then */
                (resp) => {
                    dispatch(updateIsLoading(false));
                    dispatch(getGiftCardDetails(resp));
                },
                /** @namespace Pwa/Store/MyAccount/Dispatcher/then */
                (error) => {
                    dispatch(updateIsLoading(false));
                    dispatch(showNotification('error', (error[0].message.toString())));
                    dispatch(getGiftCardDetails({}));
                }
            );
        } catch (err) {
            dispatch(updateIsLoading(false));
            dispatch(showNotification('error', (err.toString())));
            dispatch(getGiftCardDetails({}));
        }
    }

    async giftAddGiftCardMution(_options = {}, dispatch) {
        try {
            dispatch(updateIsLoading(true));
            const mutation = MyAccountQuery.getAddCardMutation(_options);
            const result = fetchMutation(mutation);
            result.then(
                /** @namespace Pwa/Store/MyAccount/Dispatcher/then */
                (resp) => {
                    // TODO success message
                    dispatch(updateIsLoading(false));
                    dispatch(showNotification('success', __('Gift Card has been added')));
                    dispatch(getAddGiftCard(resp));
                },
                /** @namespace Pwa/Store/MyAccount/Dispatcher/then */
                (error) => {
                    dispatch(updateIsLoading(false));
                    dispatch(showNotification('error', (error[0].message)));
                    dispatch(getAddGiftCard({}));
                }
            );
        } catch (err) {
            dispatch(updateIsLoading(false));
            dispatch(showNotification('error', (err.toString())));
            dispatch(getAddGiftCard({}));
        }
    }

    async removeCardMutation(_options = {}, dispatch) {
        try {
            dispatch(updateIsLoading(true));
            const query = MyAccountQuery.removecardMutation(_options);
            const result = fetchQuery(query);
            result.then(
                /** @namespace Pwa/Store/MyAccount/Dispatcher/then */
                (resp) => {
                    // TODO success message
                    dispatch(updateIsLoading(false));
                    dispatch(showNotification('success', __('success')));
                    dispatch(getRemoveGiftCard(resp));
                },
                /** @namespace Pwa/Store/MyAccount/Dispatcher/then */
                (error) => {
                    dispatch(updateIsLoading(false));
                    dispatch(showNotification('error', (error[0].message.toString())));
                    dispatch(getRemoveGiftCard({}));
                }
            );
        } catch (err) {
            dispatch(updateIsLoading(false));
            dispatch(showNotification('error', (err.toString())));
            dispatch(getRemoveGiftCard({}));
        }
    }
}

export default new MyAccountDispatcher();
