/* eslint-disable max-len */
/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { isEmpty } from 'lodash';

import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/BreadPay/Query/BreadPayQuery */
export class BreadPayQuery {
    getCalculateShippingMutation(shippingAddress, cart_id) {
        const mutation = new Field('breadPayEstimateShipping')
            .addArgument('shipping_info', 'String!', shippingAddress)
            .addArgument('cart_id', 'String!', cart_id)
            .addFieldList(this._getCalculateShippingFields());

        return mutation;
    }

    _getCalculateShippingFields() {
        return [
            'status',
            'message',
            'data'
        ];
    }

    getBreadPayEstimateTaxMutation(shippingAddress, cart_id) {
        const mutation = new Field('breadPayEstimateTax')
            .addArgument('shipping_info', 'String!', shippingAddress)
            .addArgument('cart_id', 'String!', cart_id)
            .addFieldList(this._getBreadPayEstimateTaxFields());

        return mutation;
    }

    _getBreadPayEstimateTaxFields() {
        return [
            'status',
            'message',
            'amount'
        ];
    }

    getBreadPayValidateOrderMutation(bread_pay_token, cart_id) {
        const mutation = new Field('breadPayValidateOrder')
            .addArgument('bread_pay_token', 'String!', bread_pay_token)
            .addArgument('cart_id', 'String!', cart_id)
            .addFieldList(this._getBreadPayValidateOrderFields());

        return mutation;
    }

    _getBreadPayValidateOrderFields() {
        return [
            'status',
            'message',
            'token',
            'order_id',
            'base_encode'
        ];
    }

    getBreadPayConfigMutation(cart_id) {
        const mutation = new Field('breadPayConfig')
            .addArgument('cart_id', 'String!', cart_id)
            .addFieldList(this._getBreadPayConfigFields());

        return mutation;
    }

    _getBreadPayConfigFields() {
        return [
            this._getKeyConfigs(),
            this._getTopConfigs(),
            this._getButtonConfigFields()
        ];
    }

    _getKeyConfigs = () => new Field('keyConfig')
        .addFieldList([
            'src',
            'dataApiKey'
        ]);

    _getTopConfigs = () => new Field('topLevelConfig')
        .addFieldList([
            'validateProductTypes',
            'shippingAddress',
            'billingAddress',
            'buttonOnProduct',
            'buttonCss',
            'blockKey',
            'additionalData',
            'isItemMode',
            'discount',
            this.getItemFields()
        ]);

    getItemFields = () => new Field('items')
        .addFieldList([
            'name',
            'price',
            'sku',
            'detailUrl',
            'quantity'
        ]);

    _getButtonConfigFields = () => new Field('buttonConfig')
        .addFieldList([
            'buttonId',
            'allowSplitPayCheckout',
            'asLowAs',
            'actAsLabel',
            'allowCheckout',
            'buttonLocation'
        ]);

    _getAmountFields = () => new Field('amount')
        .addFieldList([
            'Value',
            'Currency'
        ]);

    _getTermFields = () => new Field('terms')
        .addFieldList([
            this._getAmountFields(),
            'interval',
            'length',
            'rate'
        ]);

    _getPaymentAmountFields = () => new Field('paymentAmount')
        .addFieldList([
            'Value',
            'Currency'
        ]);

    _getAsLowAsFields = () => new Field('asLowAs')
        .addFieldList([
            this._getTermFields(),
            this._getPaymentAmountFields(),
            'promptText'
        ]);

    getAsLowAsAmount(amount) {
        const query = new Field('breadpayGetaslowas')
            .addArgument('amount', 'Int', amount)
            .addFieldList([
                this._getAsLowAsFields()
            ]);

        return query;
    }
}

export default new BreadPayQuery();
