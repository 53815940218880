/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable fp/no-let */
/* eslint-disable max-lines */
/* eslint-disable no-unused-vars */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
import { isEmpty } from 'lodash';

import CartQuery from 'Query/Cart.query';
import { isSignedIn } from 'Util/Auth';
import { Field } from 'Util/Query';

/** @namespace Pwa/Query/Checkout/Query/CheckoutQuery */
export class CheckoutQuery {
    getPaymentMethodsQuery(guestCartId) {
        const query = new Field('getPaymentMethods')
            .addFieldList(this._getPaymentMethodFields());

        this._addGuestCartId(guestCartId, query);

        return query;
    }

    getPaymentMethodsWithTotalQuery(guestCartId) {
        const query = new Field('getPaymentMethods')
            .addFieldList([
                this._getPaymentMethodsField(),
                this._getTotalsField()
            ]);

        this._addGuestCartId(guestCartId, query);

        return query;
    }

    getCompanyCreditQuery(guestCartId) {
        const query = new Field('companyCredit')
            .addFieldList(this._getAccountPayFields());

        this._addGuestCartId(guestCartId, query);

        return query;
    }

    getSaveGuestEmailMutation(email, cart_id) {
        const input = { email, cart_id };
        const mutation = new Field('setGuestEmailOnCart')
            .addArgument('input', 'SetGuestEmailOnCartInput', input)
            .addField(((new Field('cart')).addField('email')));

        return mutation;
    }

    getEstimateShippingCosts(address, guestCartId) {
        const mutation = new Field('estimateShippingCosts')
            .addArgument('address', 'EstimateShippingCostsAddress!', address)
            .addFieldList(this._getEstimatedShippingFields());

        this._addGuestCartId(guestCartId, mutation);

        return mutation;
    }

    getSaveAddressInformation(addressInformation, guestCartId) {
        console.log('yanu66', addressInformation, guestCartId);
        const mutation = new Field('saveAddressInformation')
            .addArgument('addressInformation', 'SaveAddressInformation!', addressInformation)
            .addFieldList(this._getSaveAddressInformationFields());

        this._addGuestCartId(guestCartId, mutation);

        return mutation;
    }

    getSetShippingAddressIdOnCart(id) {
        return new Field('SetshippingaddressQuote')
            .addArgument('shipping_address_id', 'String!', id)
            .addFieldList([
                'status'
            ]);
    }

    getSetBillingAddressOnCart(input) {
        return new Field('s_setBillingAddressOnCart')
            .addArgument('input', 'S_SetBillingAddressOnCartInput!', input)
            .addField(this._getCartField())
            .setAlias('billingAddress');
    }

    getZipMoneyMutation(cartId) {
        const mutation = new Field('zipMoney')
            .addArgument('cart_id', 'String!', cartId)
            .addFieldList([
                'redirect_uri',
                'message',
                'error'
            ]);

        // this._addGuestCartId(guestCartId, mutation);
        return mutation;
    }

    getUpdateQuoteMutation(cartId) {
        const mutation = new Field('guestQuoteCartUpdate')
            .addArgument('cart_id', 'String!', cartId)
            .addFieldList([
                'status'
            ]);

        // this._addGuestCartId(guestCartId, mutation);
        return mutation;
    }

    getRestoreQuoteMutation(cartId) {
        const mutation = new Field('restoreQuoteOnCheckout')
            .addArgument('guest_cart_id', 'String!', cartId)
            .addFieldList([
                'status'
            ]);

        // this._addGuestCartId(guestCartId, mutation);
        return mutation;
    }

    activateCart(cartId) {
        const mutation = new Field('activateCart')
            .addArgument('guest_cart_id', 'String!', cartId)
            .addFieldList([
                'status'
            ]);

        return mutation;
    }

    // getSetbillingaddressQuoteMutation() {
    //     const mutation = new Field('SetbillingaddressQuote')
    //         .addField(
    //             'status'
    //         );

    //     return mutation;
    // }

    getSetPaymentMethodOnCartMutation(input) {
        return new Field('s_setPaymentMethodOnCart')
            .addArgument('input', 'S_SetPaymentMethodOnCartInput!', input)
            .addField(this._getCartField())
            .setAlias('paymentMethod');
    }

    getPlaceOrderMutation(guestCartId) {
        const mutation = new Field('s_placeOrder')
            .setAlias('placeOrder')
            .addField(this._getOrderField());

        if (!isSignedIn()) {
            mutation.addArgument('guestCartId', 'String', guestCartId);
        }

        return mutation;
    }

    getUpdateAmazonSessionMutation(guestCartId, amazonPaySessionId) {
        const mutation = new Field('amazonCheckoutSessionUpdate')
            .setAlias('amazonCheckoutSessionUpdate')
            .addField('amazonPayRedirectUrl');

        // if (!isSignedIn()) {
        mutation.addArgument('cart_id', 'String!', guestCartId);
        // }
        mutation.addArgument('amazonPaySession', 'String!', amazonPaySessionId);

        return mutation;
    }

    getAmazonCheckoutSessionComplete(guestCartId, amazonPaySessionId) {
        const mutation = new Field('amazonCheckoutSessionComplete')
            .setAlias('amazonCheckoutSessionComplete')
            .addFieldList(['status',
                'orderId',
                'message',
                'method',
                'base_encode']);

        mutation.addArgument('cart_id', 'String!', guestCartId);
        mutation.addArgument('amazonPaySession', 'String!', amazonPaySessionId);

        return mutation;
    }

    getStripeCheckoutSessionComplete(input) {
        const mutation = new Field('stripePlaceOrder')
            .addFieldList(['status',
                'orderId',
                'message',
                'method',
                'base_encode']);

        mutation.addArgument('input', 'StripePaymentInput!', input);
        return mutation;
    }

    getUpdateShippingMethodOnCart(guestCartId, shipping_method_code, shipping_carrier_code) {
        const shipping_methods = [{ carrier_code: shipping_carrier_code, method_code: shipping_method_code }];
        const mutation = new Field('setShippingMethodsOnCart')
            .setAlias('setShippingMethodsOnCart')
            .addFieldList([this._getUpdateShippingField()]);
        // let input = {
        //     shipping_methods
        // };

        // if (!isSignedIn()) {
        const input = {
            cart_id: guestCartId,
            shipping_methods
        };

        // }
        mutation.addArgument('input', 'SetShippingMethodsOnCartInput!', input);
        return mutation;
    }

    _getUpdateShippingField() {
        return new Field('cart')
            .addField(this._getShippingFields());
    }

    _getShippingFields() {
        return new Field('shipping_addresses')
            .addField(this._getShippingMethodFields());
    }

    _getShippingMethodFields() {
        return new Field('selected_shipping_method')
            .addFieldList(['carrier_code',
                'method_code',
                'carrier_title',
                'method_title']);
    }

    _getAccountPayFields() {
        return [
            'limit',
            'exceedLimit',
            'limitFormatted',
            'quoteTotalFormatted',
            'exceededAmountFormatted',
            'exceededAmount',
            'currency',
            'rate',
            'baseRate',
            'isBaseCreditCurrencyRateEnabled',
            'priceFormatPattern',
            'companyName'
        ];
    }

    _addGuestCartId(guestCartId, mutation) {
        if (guestCartId && !isSignedIn()) {
            mutation.addArgument('guestCartId', 'String!', guestCartId);
        }
    }

    // _getOrderField() {
    //     return new Field('order')
    //         .addFieldList(['order_id']);
    // }

    _getOrderField() {
        return new Field('order')
            .addFieldList(['order_id', 'base_encode', 'payone_redirect_url', 'payjustnow_redirect_url']);
    }

    _getSavePaymentInformationAndPlaceOrderFields() {
        return [
            'orderID',
            'base_encode',
            'payone_redirect_url',
            'payjustnow_redirect_url'

        ];
    }

    getSavePaymentInformationAndPlaceOrder(paymentInformation, guestCartId) {
        let updatedPaymentInformation = paymentInformation;
        const { paymentMethod, ...input } = paymentInformation;
        if (!isEmpty(paymentMethod)) {
            const updatedPaymentMethod = {
                ...paymentMethod,
                extension_attributes: {
                    agreement_ids: ['1'] // Todo: Pick from state.configreducer.checkoutagreements.0.agreement_id
                }
            };

            updatedPaymentInformation = { ...input, paymentMethod: updatedPaymentMethod };
        }

        const mutation = new Field('savePaymentInformationAndPlaceOrder')
            .addArgument('paymentInformation', 'PaymentInformation!', updatedPaymentInformation)
            .addFieldList(this._getSavePaymentInformationAndPlaceOrderFields());

        this._addGuestCartId(guestCartId, mutation);

        return mutation;
    }

    _getSaveAddressInformationFields() {
        return [
            this._getPaymentMethodsField(),
            this._getTotalsField()
        ];
    }

    _getEstimatedShippingFields() {
        return [
            'amount',
            'available',
            'base_amount',
            'method_code',
            'carrier_code',
            'method_title',
            'carrier_title',
            'error_message',
            'price_excl_tax',
            'price_incl_tax'
        ];
    }

    _getPaymentMethodsField() {
        return new Field('payment_methods')
            .addFieldList(this._getPaymentMethodFields());
    }

    _getPaymentMethodFields() {
        return ['code', 'title'];
    }

    _getTotalItemFields() {
        return [
            'qty',
            'sku',
            'name',
            'price',
            'item_id',
            'row_total',
            'options',
            'row_total_incl_tax',
            'tax_amount',
            'tax_percent',
            'price_incl_tax',
            'discount_amount',
            'discount_percent',
            CartQuery._getCustomizableOptionsFields(),
            CartQuery._getBundleOptionsField(),
            CartQuery._getProductField()
        ];
    }

    _getTotalItemField() {
        return new Field('items')
            .addFieldList(this._getTotalItemFields());
    }

    _getTotalsFields() {
        return [
            'subtotal',
            'tax_amount',
            'base_grand_total',
            'grand_total',
            'discount_amount',
            'shipping_amount',
            'subtotal_incl_tax',
            'shipping_incl_tax',
            'quote_currency_code',
            'shipping_tax_amount',
            'subtotal_with_discount',
            'shipping_discount_amount',
            'gift_code_label',
            'gift_code_discount',
            this._getCheckoutSegmentFields(),
            this._getTotalItemField()
            // CartQuery._getAppliedTaxesField()
        ];
    }

    _getCheckoutSegmentFields() {
        return new Field('total_segments')
            .addFieldList(['code', 'title', 'value']);
    }

    _getTotalsField() {
        return new Field('totals')
            .addFieldList(this._getTotalsFields());
    }

    _getCartField() {
        return new Field('cart')
            .addFieldList(this._getCartFieldList());
    }

    _getCartFieldList() {
        return [
            'id'
        ];
    }

    getApplyGiftCodeMutation(giftCode, quoteId) {
        const cart_id = this._getCartId(quoteId);
        const input = { am_gift_card_code: giftCode, cart_id };
        const mutation = new Field('applyAmGiftCardToCart')
            .addArgument('input', 'ApplyAmGiftCardToCartInput!', input)
            .addFieldList(this._getCallbackResponseFieldsOfGiftCode());

        return mutation;
    }

    getRemoveGiftCodeMutation(giftCode, quoteId) {
        const cart_id = this._getCartId(quoteId);
        const input = { am_gift_card_code: giftCode, cart_id };
        const mutation = new Field('removeAmGiftCardFromCart')
            .addArgument('input', 'RemoveAmGiftCardFromCartInput!', input)
            .addFieldList(this._getCallbackResponseFieldsOfGiftCode());

        return mutation;
    }

    /**
     * apply gift code queries
     */

    _getCartId(quoteId) {
        const cart_id = quoteId;
        return cart_id;
    }
    /**
     *
     * @param {get gift code details } giftCode
     * @param {*} quoteId
     */

    getGiftCodeDetailsQuery(giftCode, quoteId) {
        const cart_id = this._getCartId(quoteId);
        const input = { am_gift_card_code: giftCode };
        const query = new Field('amGiftCardAccount')
            .addArgument('input', 'AmGiftCardAccountInput!', input)
            .addFieldList(this._getCardDetailsFields());

        return query;
    }

    getSetPaymentMethodOnCartMutationMagento(input) {
        return new Field('setPaymentMethodOnCart')
            .addArgument('input', 'SetPaymentMethodOnCartInput!', input)
            .addField(this._getCartField());
    }

    getSetAmastyOrderattributeOnCartMutationMagento(amastyOrderattributeInput, cartId) {
        let updatedAmastyOrderattributeInput = {};
        updatedAmastyOrderattributeInput = { ...amastyOrderattributeInput, amastyCartId: cartId };
        const mutation = new Field('setAmastyOrderattribute')
            .addArgument('amastyOrderattributeInput', 'AmastyOrderattributeInput!', updatedAmastyOrderattributeInput)
            .addFieldList([
                'status'
            ]);

        return mutation;
    }

    _getCartFieldsList() {
        return [
            this._getAppliedGiftCardFields(),
            this._getPaymentDetailsField(),
            'gift_code_label',
            'gift_code_discount'
        ];
    }

    AuthnetPaymentMutation(input, cart_id) {
        // cart_id is supplied when user is not logged in
        // This can be discussed with someone who has knowledge in magento, for a better implementation.
        const method = isEmpty(cart_id) ? this.getSetPaymentMethodOnCartMutation.bind(this) : this.getSetPaymentMethodOnCartMutationMagento.bind(this);
        return [
            method(input),
            this.getPlaceOrderMutation(cart_id)
        ];
    }

    _getPaymentDetailsField() {
        return new Field('payment_details')
            .addFieldList([this._getPaymentMethodsField(), this._getTotalsField()]);
    }

    /**
     *
     * @param {get applied gift code details } giftCode
     * @param {*} quoteId
     */

    getAppliedGiftCodesQuery(quoteId) {
        const query = (isSignedIn())
            ? new Field('customerCart')
                .addFieldList(this._getCartFieldsList())
            : new Field('cart')
                .addArgument('cart_id', 'String!', quoteId)
                .addFieldList(this._getCartFieldsList());

        return query;
    }

    _getCallbackResponseFieldsOfGiftCode() {
        return [
            this._getCartDataFieldsAfterGiftCode(),
            this._getPaymentDetailsField()
        ];
    }

    _getCartDataFieldsAfterGiftCode() {
        return new Field('cart')
            .addFieldList(this._getCartFieldsList());
    }

    _getCurrentBalanceFields() {
        return new Field('current_balance')
            .addFieldList(this._getBalanceFields());
    }

    _getAppliedBalanceFields() {
        return new Field('applied_balance')
            .addFieldList(this._getBalanceFields());
    }

    _getBalanceFields() {
        return [
            'value',
            'currency'
        ];
    }

    _getCardDetailsFields() {
        return [
            'code',
            'expiration_date',
            'status',
            this._getCurrentBalanceFields()
        ];
    }

    _getAppliedGiftCardFields() {
        return new Field('applied_am_gift_cards')
            .addFieldList(this._getGiftCardFields())
            .addField(this._getAppliedBalanceFields());
    }

    _getGiftCardFields() {
        return [
            'code',
            'expiration_date',
            this._getCurrentBalanceFields()
        ];
    }

    getAmastyOrderAttributesQuery(guestCartId) {
        const query = new Field('getAmastyOrderAttributes')
            .addFieldList(this._getAmastyOrderAttributesFields());

        return query;
    }

    _getAmastyOrderAttributesFields() {
        return [
            'scope',
            this._getChildrenItemField()

        ];
    }

    _getChildrenItemField() {
        return new Field('children')
            .addFieldList([
                'dataType',
                'code',
                'label',
                'visible',
                'required',
                'notice',
                'sortOrder',
                this._getOptionsFields()
            ]);
    }

    _getOptionsFields() {
        return new Field('options')
            .addFieldList([
                'label',
                'value'
            ]);
    }

    getPaymentData({ order_id, type }) {
        const input = { order_id, type };
        const query = new Field('getPaymentFormData')
            .addArgument('order_id', 'String!', order_id)
            .addArgument('type', 'String!', type)
            .addFieldList(this._getPaymentDataResponse());

        return query;
    }

    _getPaymentDataResponse() {
        return ([
            'message',
            'PAY_REQUEST_ID',
            'CHECKSUM',
            'form_url'
        ]);
    }

    getSelectedCheckoutOptions(cart_id) {
        const query = new Field('cart')
            .addArgument('cart_id', 'String!', cart_id)
            .setAlias('selectedCheckoutOptions')
            .addFieldList([
                'email',
                this._getBillingAddress(),
                this._getShippingAddress()
            ]);

        return query;
    }

    _getBillingAddress() {
        return new Field('billing_address')
            .addFieldList(this._getBillAddressFields());
    }

    _getShippingAddress() {
        return new Field('shipping_addresses')
            .addFieldList([
                'city',
                'firstname',
                'lastname',
                'postcode',
                'street',
                'telephone',
                'company',
                'vat_id',
                this._getRegionField(),
                this._getCountryField(),
                this._getSelectedShippingMethod()
            ]);
    }

    _getSelectedShippingMethod() {
        return new Field('selected_shipping_method')
            .addFieldList([
                this._getAmount(),
                'carrier_code',
                'carrier_title',
                'method_code',
                'method_title'
            ]);
    }

    _getBillAddressFields() {
        return [
            'city',
            'firstname',
            'lastname',
            'postcode',
            'street',
            'telephone',
            this._getRegionField(),
            this._getCountryField()
        ];
    }

    _getAmount() {
        return new Field('amount')
            .addFieldList([
                'value',
                'currency'
            ]);
    }

    _getCountryField() {
        return new Field('country')
            .addFieldList([
                'code',
                'label'
            ]);
    }

    _getRegionField() {
        return new Field('region')
            .addFieldList([
                'code',
                'label'
            ]);
    }

    _getVehicleMake(make) {
        const { id, name } = make;
        return new Field('make')
            .addArgument('id', 'String!', id)
            .addArgument('name', 'String!', name);
    }

    _getVehicleModel(model) {
        const { id, name } = model;
        return new Field('make')
            .addArgument('id', 'String!', id)
            .addArgument('name', 'String!', name);
    }

    _getVehicleOption(option) {
        const { id, name } = option;
        return new Field('make')
            .addArgument('id', 'String!', id)
            .addArgument('name', 'String!', name);
    }

    captureVehicle(vehicleInput) {
        const {
            selectedVehicle,
            guestCartId
        } = vehicleInput;

        const {
            id, make, model, option, year
        } = selectedVehicle;

        const input = {
            id,
            year: Math.floor(Number(year)),
            orderid: guestCartId,
            make: {
                id: make.id,
                name: make.name
            },
            model: {
                id: model.id,
                name: model.name
            },
            option: {
                id: option.id,
                name: option.name
            }

        };

        return new Field('capturevehicle')
            .addArgument('input', 'capturevehicledata!', input)
            .addFieldList([
                'status'
            ]);
    }
}

export default new CheckoutQuery();
