/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/no-danger */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable fp/no-let */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import Link from 'Component/Link';

import { isAusStore, isEurStore, isRsaStore } from '../../util/FrontRunner/Store';

import './Faq.style.scss';
/** @namespace Pwa/Component/YotpoLoyalty/Component/YotpoLoyaltyComponent */
export class YotpoLoyalty extends Component {
    getItemState({ expanded }) {
        return (
            <div className="toggle-nav">
            { expanded ? '-' : '+' }
            </div>
        );
    }

    renderEmailAddress() {
        let emailAddress = 'info@frontrunneroutfitters.com';
        if (isRsaStore()) {
            emailAddress = 'info@frontrunner.co.za';
        }
        if (isAusStore()) {
            emailAddress = 'info@frontrunneroutfitters.com.au';
        }
        if (isEurStore()) {
            emailAddress = 'info.eu@frontrunneroutfitters.com';
        }

        return (
            <a href={ `mailto:${emailAddress}` }>
                { emailAddress }
            </a>
        );
    }

    renderFAQAccordions() {
        let currencyCode = 'USD';
        let currencySymbol = '$';
        let vip1total = 0;
        let vip2total = 1500;
        let vip3total = 3000;

        let pointsMin = 400;

        if (isRsaStore()) {
            currencyCode = 'ZAR';
            currencySymbol = 'R';
            vip1total = 0;
            vip2total = 15000;
            vip3total = 30000;
        }

        // Format the totals
        vip1total = currencySymbol + vip1total;
        vip2total = currencySymbol + vip2total;
        vip3total = currencySymbol + vip3total;

        return (
          <div className="faqAccordionWrapper">
            <h2>Frequently Asked Questions</h2>
            <Accordion allowZeroExpanded>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Signing Up
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How do I sign up for the Front Runner Explorer Rewards program?</h4>
                        <p>
                            Signing up is easy! Simply create an account
                            { ' ' }
                            <Link to="/login" target="_blank" rel="noopener">here</Link>
                            { ' ' }
                            and enter your name, email, and password. Ensure that the checkbox
                            is selected to join the Explorer Rewards program. It's free to join and you will automatically be
                            signed up to receive emails with insider access to sales, promotions, and all of our newest drops.
                            If you currently have an account or email subscription with us - you've already been enrolled in
                            our rewards program so you can kick back, sign in, and start earning Points!
                        </p>

                        <h4>What are the advantages of becoming an Explorer Rewards member?</h4>
                        <p>
                            The Explorer Rewards program is a tiered program that allows members to earn more, the more
                            they engage with us. Members receive reward points for each purchase, which can then be
                            redeemed towards the cost of future purchases.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Account
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>I already have a Front Runner account. Do I need to create a new one to earn rewards?</h4>
                        <p>
                            We took care of that for you! If you have an active account with us, you were automatically
                            enrolled in our rewards program. If you have had more than one customer account with us,
                            please note that only one rewards account was created per person.
                        </p>
                        <h4>Where can I find all of my rewards account info?</h4>
                        <p>
                            The Explorer Rewards page is your roadmap to all your points and perks. You can access all
                            your info by visiting our
                            { ' ' }
                            <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                            { ' ' }
                            .
                        </p>
                        <h4>Will I receive points for purchases I made prior to creating my rewards account?</h4>
                        <p>
                            You will only receive points for purchases made after creating your rewards account. Make sure
                            to create an account before placing an order so you can receive points!
                        </p>
                        <h4>
                            What if I have an old email address or multiple email addresses and want to combine my
                            accounts &amp; earnings?
                        </h4>
                        <p>
                            Contact customer service by emailing { this.renderEmailAddress() } and our team will be happy
                            to assist you with merging your accounts.
                        </p>
                        <h4>Does it cost anything to begin earning points?</h4>
                        <p>
                            Absolutely not! Sign-up is 100% free, and it will never cost you anything to earn points. Make
                            sure to visit the
                            { ' ' }
                            <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                            { ' ' }
                            to get started.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                             Earning &amp; Redemption
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How do I redeem my points?</h4>
                        <p>
                        Exchanging your points for great rewards couldn&#39;t be easier! Simply visit the
                        { ' ' }
                        <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                        { ' ' }
                        to view all of our great reward options and click the &#39;Redeem&#39;
                        button to redeem your chosen reward. However, loyalty discounts and any other coupon codes
                        cannot be used in conjunction at the same time. Loyalty discounts cannot redeemed against
                        purchases during promotional events.
                        </p>
                        <h4>How do points convert to { currencyCode }?</h4>
                        <p>
                        Higher tiers in the VIP tier program earn more points at a quicker rate, but 20 points always = 1 { currencyCode }.
                        <br />
                        For example, as an Explorer you will earn 1 point for every 1 { currencyCode } you spend.
                        <br />
                        When you move up to become a Pioneer you will be earning points 1.25x as fast.
                        <br />
                        Moving up to become a Trailblazer you will be earning points 1.5x as fast.
                        </p>
                        <h4>What are the easiest ways to earn points?</h4>
                        <p>
                        For starters- each member gets a reward just for joining the Explorer Rewards program. Aside
                        from earning points through purchases, you can add your birthday to your account to earn points,
                        leave us a review, or like us on Instagram. Access all the ways to earn under the ways to earn
                        points on the
                        { ' ' }
                        <Link to="/rewards" target="_blank" rel="noopener">rewards page</Link>
                        { ' ' }
                        .
                        </p>
                        <h4>Will I be able to use my points/rewards in-store?</h4>
                        <p>
                        You will not be able to earn or apply rewards points towards purchases in-store at this time.
                        We are working to link our stores with our online site in future.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Point Limitations
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Is there a limit to how many points I can earn?</h4>
                        <p>
                            Nope! That's the best part about being a rewards member! You will always have an opportunity
                            to earn more points by shopping with us, engaging with our brand and community online.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Returns
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Can I return my order if I redeemed rewards points for a discount?</h4>
                        <p>
                        Yes. If you are returning the entire order, we will refund you the total dollar amount spent after
                        the discount was applied. The points you used to redeem the discount will also be added back to
                        your rewards account.
                        </p>
                        <h4>
                            I earned points/VIP tier with my last purchase, but now I want to return it. What will happen to my reward?
                        </h4>
                        { isRsaStore() && (
                            <p>
                            If you use your rewards towards a purchase and later decide to return that item, your rewards will
                            be credited back to your rewards account. For example, if you use a R400 coupon towards a R1000
                            purchase that you decide to return, the R600 balance will be refunded to you and the R400 reward
                            credit will be deposited back into your rewards account.
                            </p>
                        ) } { !isRsaStore() && (
                            <p>
                            If you use your rewards towards a purchase and later decide to return that item, your rewards will
                            be credited back to your rewards account. For example, if you use a $20 coupon towards a $100
                            purchase that you decide to return, the $80 balance will be refunded to you and the $20 reward
                            credit will be deposited back into your rewards account.
                            </p>
                        ) }

                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Reward Expiration
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Do points ever expire?</h4>
                        <p>
                        Points and rewards and your VIP tier will expire if you fail to make at least 1 purchase every 12
                        months. If we ever decide to end the Explorer Rewards program, your points and perks will
                        expire with the program. We also reserve the right to terminate any person's rewards
                        membership if we believe you are violating our
                        { ' ' }
                        <Link to="/terms-of-use" target="_blank" rel="noopener">Terms of Use</Link>
                        { ' ' }
                        , reselling products, exploiting the program, making excessive
                        returns, engaging in fraud, abuse of privileges, or inappropriate behavior that seeks to
                        manipulate the rewards program, or for any other reason we deem necessary based on our sole
                        discretion. In the event your account is terminated by you or us, or you opt out of the rewards
                        program, you will lose any unused rewards as well as all points that have not been redeemed.
                        </p>
                        <p>
                        Rewards points are valid for the remainder of the calendar year in which they are earned, plus
                        the following calendar year.
                        </p>
                        <p>
                            For example: points earned anytime during the year 2024 are valid for the remainder of 2024,
                            plus all of 2025. Points will start over at 0 on January 1, 2026.
                        </p>
                        <p>
                            Rewards points will expire after 12 months of Explorer Rewards account inactivity. This means
                            that if there is no change to your Rewards account balance (through either the addition or
                            redemption of Rewards), all Rewards earned in the previous 12 months will automatically be
                            removed from your account.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            VIP Program
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How is my VIP tier determined?</h4>
                        <p>
                        Your VIP tier is determined by the amount you spend in a calendar year (for example, Jan 1, 2024 - Dec
                        31, 2024).
                        <br />
                        To reach:
                        <br />
                        Explorer, you must spend { vip1total }
                        <br />
                        Pioneer, you must spend { vip2total }+
                        <br />
                        Trailblazer, you must spend { vip3total }+
                        </p>
                        <p>
                        Once you qualify, you will instantly move up and have access to that tier's perks through the rest
                        of the current calendar year as well as the following calendar year. As you move up in the VIP
                        Tiers, you will retain all benefits from your previous tier.
                        </p>
                        <h4>How do I know which Rewards tier I am currently a member of?</h4>
                        <p>
                        When you are signed into your rewards account, your home page will list all of the rewards tiers.
                        Your current tier and perks will be highlighted with a box around it.
                        </p>
                        <h4>How will I know when I have reached a new tier?</h4>
                        <p>
                        When your total points cross a tier threshold you will receive an email welcoming you into the
                        new tier, for example, Explorer, Pioneer or Trailblazer.
                        </p>
                        <h4>Tier Expiration</h4>
                        <p>
                        Once you are in an Explorer, Pioneer or Trailblazer tier, you will be in that tier for one year from the date
                        you earned it. Make sure to keep interacting with our program to maintain your status (or even
                        move up a tier)!
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Troubleshooting
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>Why didn't I earn points on my purchase?</h4>
                        <p>
                        Double-check you were signed into your Explorer Rewards account and didn't accidentally check
                        out as a guest. You must be logged in when making purchases to earn points. If you have done
                        this in error, contact us at { this.renderEmailAddress() } to help apply your points to your
                        account. You also won't receive points if you return your purchase or your purchase is cancelled
                        for any reason. Please note that any points you earn on a purchase you later return will be
                        deducted from your point balance.
                        </p>
                        <h4>
                        I should have earned points for a purchase/incentive but I didn't see my account balance
                        change. Did I get credit?
                        </h4>
                        <p>
                        Yes! You work hard to earn points and we want you to feel confident that all of your points are
                        right where they should be... in your account! The first thing to note is that there is a delay of 30
                        days for purchase points to appear in your account and serval hours for incentive points to be
                        allocated. However, if you feel like you have earned points but they are not showing up in your
                        account you can also troubleshoot it with these tricks:
                        </p>
                        <p>
                        1. Try to refresh your browser.
                        { ' ' }
                        <br />
                        2. Try to log out and then back in to your account.
                        { ' ' }
                        <br />
                        3. Check your 'Rewards history' in your Rewards account to see if the points were added
                        without you realizing it. Your 'Rewards history' will show you the number of points
                        earned, how and when they were earned, as well as any redemptions of points made on
                        your account.
                        </p>
                        <p>
                        If you still feel like your points balance is not accurate you can contact our customer support
                        team at { this.renderEmailAddress() } and they will look into the issue for you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Birthday Reward
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How can I get a birthday reward?</h4>
                        <p>
                        Add your birthday under your account details and on your birthday we will update your account
                        with points.
                        </p>
                        <h4>I signed up for the Birthday reward but I didn't receive it.</h4>
                        <p>
                            Happy Birthday! We're so glad that you have chosen to celebrate with us through our rewards
                            program. In order for you to receive your birthday points on your birthday, you must register your
                            birthday at least 30 days prior to your birthday.
                        </p>
                        <p>
                        If your birthday falls between the day that you registered and the 30-day waiting period afterward,
                        you will receive your birthday bonus 30 days after your registration. This delay is only applicable
                        for the first year that you signed up.
                        </p>
                        <p>
                        All successive birthday points will be emailed to you on your birthday. If you register your
                        birthday through your rewards account any time after it has passed in the current calendar year,
                        points will not be added to your account until the day of your birthday in the following year.
                        </p>
                        <p>
                        Remember, birthday points are determined by the Rewards member tier that you are part of on
                        the date of your birthday. If it has been more than 30 days since you registered for your birthday
                        reward and you still have not received the additional points in your account, please contact our
                        customer support team at { this.renderEmailAddress() } and we will be happy to assist you.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Checkout
                            <AccordionItemState>
                                { this.getItemState }
                            </AccordionItemState>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <h4>How do I redeem my rewards points?</h4>
                        <p>
                            Rewards points can be redeemed during the checkout process. Before checking out, ensure that
                            you are signed in to your account.
                        </p>
                        <p>
                            Once you get to the billing screen you will see your total available Rewards points in a slide
                            bar on the top left of the page. To use your rewards, slide the points redemption bar to select the
                            number of points you would like to redeem on the order you are placing, then continue with the
                            checkout process. To redeem all available Rewards slide the bar all the way to the right.
                        </p>
                        <p>
                            You must have a minimum balance of at least 400+ points each time you would like to make a
                            redemption, and points must be redeemed in increments of 400.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
          </div>
        );
    }

    render() {
        return (
          <div className="faqAccordion">
             { this.renderFAQAccordions() }
          </div>
        );
    }
}

export default YotpoLoyalty;
