/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author  Edgars Kuzmanis <info@scandiweb.com>
 */
import { isEmpty } from 'lodash';

export const renderActions = (args, callback, instance) => {
    const { selectedShippingMethod, estimateAddress = {} } = instance.props;
    const { region_id } = estimateAddress;
    const { method_code } = selectedShippingMethod || {};
    // eslint-disable-next-line eqeqeq
    const isDisabled = isEmpty(method_code) || region_id == -1;

    return (
        <div block="Checkout" elem="StickyButtonWrapper">
            <button
              type="submit"
              block="Button"
              disabled={ isDisabled }
              mix={ { block: 'CheckoutShipping', elem: 'Button' } }
            >
                { __('Proceed to billing') }
            </button>
            <div className="tooltip">
                Please fill all the required field.
            </div>
        </div>
    );
};

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            renderActions
        }
    }
};
