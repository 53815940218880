/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable no-unused-vars */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-conditional */
/* eslint-disable no-magic-numbers */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-unused-state */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemState
} from 'react-accessible-accordion';

import isMobile from 'Util/Mobile';

// import { renderLink } from 'Util/Helper';
import 'react-tabs/style/react-tabs.scss';
import './ProductAccordions.style.scss';

/** @namespace Pwa/Component/ProductAccordions/Component/ProductAccordionsComponent */
export class ProductAccordions extends PureComponent {
    __construct(props) {
        super.__construct(props);
        const { selectedInxData } = this.props;
        this.state = {
            selectedInxDatas: selectedInxData
        };
    }

  static propTypes = {
      wrapperClass: PropTypes.string
  };

  static defaultProps = {
      wrapperClass: 'React-Tabs'
  };

  getItemState({ expanded }) {
      const className = expanded ? 'expanded' : 'collapsed';

      return (
        <div className={ `toggle-nav ${className}` }>
          {/* { expanded ? <FontAwesomeIcon icon={ faChevronUp } /> : <FontAwesomeIcon icon={ faChevronDown } /> } */}
          <FontAwesomeIcon icon={ faChevronDown } />
        </div>
      );
  }

  render() {
      const { wrapperClass, data, selectedInxData } = this.props;
      const { selectedInxDatas } = this.state;
      const { label, content } = data;
      if (selectedInxData === 3) {
          this.setState({ selectedInxDatas: 3 });
      }

      let multiExp = false;
      if (isMobile.any()) {
          multiExp = true;
      }

      return (
        <Accordion
          allowZeroExpanded
          allowMultipleExpanded={ multiExp }
          className={ wrapperClass }
          preExpanded={[0]}
          selectedIndex={ selectedInxDatas }
          onSelect={ (selectedInxDatas) => this.setState({ selectedInxDatas }) }
        >
          { map(label, (l, key) => (
              (l && (
              <AccordionItem key={ key } uuid={key}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                        { l }
                        <AccordionItemState>
                            { this.getItemState }
                        </AccordionItemState>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    {content[key]}
                  </AccordionItemPanel>
              </AccordionItem>
              ))
          )) }

          {/* TODO */}
          {/* <AccordionItem uuid="lifetime-warranty">
            <AccordionItemHeading>
              <AccordionItemButton>
                  Lifetime Warranty
                  <AccordionItemState>
                      { this.getItemState }
                  </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
               when an unknown printer took a galley of type and scrambled it to make a type specimen book.
               It has survived not only five centuries, but also the leap into electronic typesetting,
            </AccordionItemPanel>
          </AccordionItem> */}

          {/* TODO */}
          {/* <AccordionItem uuid="shipping-returns">
            <AccordionItemHeading>
              <AccordionItemButton>
                  Shipping & Returns
                  <AccordionItemState>
                      { this.getItemState }
                  </AccordionItemState>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
               when an unknown printer took a galley of type and scrambled it to make a type specimen book.
               It has survived not only five centuries, but also the leap into electronic typesetting,
            </AccordionItemPanel>
          </AccordionItem> */}
        </Accordion>
      );
  }
}

export default ProductAccordions;
