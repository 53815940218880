/* eslint-disable react/prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable react/sort-comp */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
    Children,
    cloneElement,
    createRef,
    PureComponent
} from 'react';

import { FieldContainer } from 'Component/Field/Field.container';
import { ChildrenType, MixType } from 'Type/Common.type';
import FormPortalCollector from 'Util/FormPortalCollector';

import validationConfig from './Form.config';

/** @namespace Component/Form/Component */
export class Form extends PureComponent {
    static propTypes = {
        onSubmitSuccess: PropTypes.func,
        onSubmitError: PropTypes.func,
        onSubmit: PropTypes.func,
        children: ChildrenType.isRequired,
        id: PropTypes.string,
        mix: MixType
    };

    static defaultProps = {
        onSubmitSuccess: () => {},
        onSubmitError: () => {},
        onSubmit: () => {},
        mix: {},
        id: ''
    };

    static updateChildrenRefs(props, state = {}) {
        const { children: propsChildren } = props;
        const { refMap: refMapState = {} } = state;

        const refMap = {};

        const children = Form.cloneChildren(
            propsChildren,
            (child) => {
                const { props: { name } } = child;
                const { message } = Object.keys(refMapState).length
                    ? Form.validateField(child, refMapState)
                    : {};

                refMap[name] = createRef();

                const childProps = {
                    formRef: refMap[name],
                    formRefMap: refMap
                };

                if (message) {
                    childProps.message = message;
                }

                return cloneElement(child, childProps);
            }
        );

        return { children, refMap };
    }

    static cloneChildren(originChildren, fieldCallback) {
        const executeClone = (originChildren) => Children.map(originChildren, (child) => {
            if (child && typeof child === 'object' && child.type && child.props) {
                const { type: { name }, props, props: { children } } = child;

                if (name === FieldContainer.prototype.constructor.name) {
                    return fieldCallback(child);
                }

                if (typeof children === 'object') {
                    return cloneElement(child, {
                        ...props,
                        children: executeClone(children)
                    });
                }

                return child;
            }

            return child;
        });

        return executeClone(originChildren);
    }

    static cloneAndValidateChildren(propsChildren, refMap) {
        const invalidFields = [];
        const children = Form.cloneChildren(
            propsChildren,
            (child) => {
                const { props: { id, name } } = child;
                const { message } = Form.validateField(child, refMap);

                if (message) {
                    invalidFields.push(id);

                    return cloneElement(child, {
                        message,
                        formRef: refMap[name]
                    });
                }

                return cloneElement(child, {
                    formRef: refMap[name]
                });
            }
        );

        return { children, fieldsAreValid: !invalidFields.length, invalidFields };
    }

    static validateField(field, refMap) {
        const { validation, id, name } = field.props;

        if (validation && id && refMap[name] && refMap[name].current) {
            const { current: inputNode } = refMap[name];

            const rule = validation.find((rule) => {
                if (!validationConfig[rule]) {
                    return false;
                }
                const validationRules = validationConfig[rule];
                const isValid = validationRules.validate(inputNode, refMap);

                return !isValid;
            });

            if (rule) {
                return validationConfig[rule];
            }
        }

        return {};
    }

    __construct(props) {
        super.__construct(props);

        if (!window.formPortalCollector) {
            window.formPortalCollector = new FormPortalCollector();
        }

        this.state = {
            ...Form.updateChildrenRefs(props),
            fieldError: '',
            fieldsAreValid: true
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { refMap } = state;
        const { children } = props;

        return {
            ...Form.cloneAndValidateChildren(children, refMap),
            ...Form.updateChildrenRefs(props, state)
        };
    }

    handleOnchange= async (_) => {
        const {
            id,
            // eslint-disable-next-line no-unused-vars
            onChange
        } = this.props;

        const data = id ? await window.formPortalCollector.collect(id) : [];
        console.log('yanu56', data);
        if (isEmpty(data)) {
            return;
        }
        const validationIssues = [];
        data.reduce((acc, portalData) => {
            const {
                invalidFields = []
            } = portalData;

            if (!isEmpty(invalidFields)) {
                validationIssues.push(...invalidFields);
            }
            // onChange(validationIssues?.length === 0);
        });
    };

    handleFormSubmit = async (e) => {
        const {
            onSubmitSuccess,
            onSubmitError,
            onSubmit,
            id
        } = this.props;

        console.log('yanu55', this.props);

        e.preventDefault();

        onSubmit();

        console.log('yanu55', this.props, id);

        const portalData = id ? await window.formPortalCollector.collect(id) : [];

        console.log('yanu24', portalData, window.formPortalCollector, id);

        // const portalData = [
        //     {
        //         invalidFields: [],
        //         inputValues: {
        //             city: 'Fayetteville',
        //             country_id: 'US',
        //             firstname: 'yash',
        //             lastname: 'yadav',
        //             postcode: '28301',
        //             region_id: '44',
        //             street0: '537 Twin Willow Lane',
        //             telephone: '1234'
        //         }
        //     }
        // ];

        console.log('yanu55', this.props, portalData);

        const {
            invalidFields,
            inputValues
        } = portalData.reduce((acc, portalData) => {
            const {
                invalidFields = [],
                inputValues = {}
            } = portalData;

            const {
                invalidFields: initialInvalidFields,
                inputValues: initialInputValues
            } = acc;

            return ({
                invalidFields: [...initialInvalidFields, ...invalidFields],
                inputValues: { ...initialInputValues, ...inputValues }
            });
        }, this.collectFieldsInformation());

        const asyncData = Promise.all(portalData.reduce((acc, { asyncData }) => {
            if (!asyncData) {
                return acc;
            }

            return [...acc, asyncData];
        }, []));

        console.log('yanu55', asyncData);

        asyncData.then(
            /** @namespace Component/Form/Component/handleFormSubmitAsyncDataThen */
            (asyncDataList) => {
                if (!invalidFields.length) {
                    console.log('yanu55 success', inputValues, asyncDataList);

                    onSubmitSuccess(inputValues, asyncDataList);

                    return;
                }
                console.log('yanu55 error', inputValues, asyncDataList);

                onSubmitError(inputValues, invalidFields);
            },
            /** @namespace Component/Form/Component/handleFormSubmitAsyncDataCatch */
            (e) => onSubmitError(inputValues, invalidFields, e)
        );
    };

    collectFieldsInformation = () => {
        const { refMap } = this.state;
        const { children: propsChildren } = this.props;

        const {
            children,
            fieldsAreValid,
            invalidFields
        } = Form.cloneAndValidateChildren(propsChildren, refMap);

        this.setState({ children, fieldsAreValid });
        const inputValues = Object.values(refMap).reduce((inputValues, input) => {
            const { current } = input;
            if (current && current.id && current.value) {
                const { name, value, checked } = current;

                if (current.dataset.skipValue === 'true') {
                    return inputValues;
                }

                if (current.type === 'checkbox') {
                    const boolValue = checked;

                    return { ...inputValues, [name]: boolValue };
                }

                return { ...inputValues, [name]: value };
            }

            return inputValues;
        }, {});

        return {
            inputValues,
            invalidFields
        };
    };

    render() {
        const { mix, id } = this.props;
        const { children, fieldsAreValid, fieldError } = this.state;

        return (
            <form
              block={ `Form ${fieldError}` }
              mix={ mix }
              mods={ { isInvalid: !fieldsAreValid } }
              ref={ (ref) => {
                  this.form = ref;
              } }
              id={ id }
              onChange={ this.handleOnchange }
              onSubmit={ this.handleFormSubmit }
            >
                { children }
            </form>
        );
    }
}

export default Form;
