/* eslint-disable global-require */
/* eslint-disable new-cap */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable radix */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable fp/no-let */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-lines */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable consistent-return */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
import { createInMemoryCache } from '@algolia/cache-in-memory';
import {
    faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import algoliasearch from 'algoliasearch/lite';
import CryptoJS from 'crypto-js';
import { debounce, get, isEmpty } from 'lodash';
import { Suspense } from 'react';
import {
    ClearRefinements,
    Configure,
    connectHits,
    connectSearchBox,
    connectStateResults,
    Highlight,
    Hits,
    InstantSearch,
    MenuSelect,
    Pagination,
    RefinementList,
    SortBy,
    ToggleRefinement,
    useInstantSearch
} from 'react-instantsearch-dom';
import searchInsights from 'search-insights';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';

import ClickOutside from 'Component/ClickOutside';
// import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import CloseIcon from 'Component/CloseIcon';
import Image from 'Component/Image';
import Link from 'Component/Link';
import SearchIcon from 'Component/SearchIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    SearchField as SourceSearchField
    // SearchOverlay as SourceSearchOverlay
} from 'SourceComponent/SearchField/SearchField.component';
import SearchBox from 'Util/Algolia/DebouncedSearchBox';
import { isSignedIn } from 'Util/Auth';
import {
    isAusStore, isEurStore, isIntStore,
    isRsaStore, isUsaStore
} from 'Util/FrontRunner/Store';
import { isB2BUser, md5, scrollToTop } from 'Util/Helper';
import history from 'Util/History';
import { formatPrice, removeCurrencySymbol, roundPrice } from 'Util/Price';
import { appendWithStoreCode } from 'Util/Url';

import { algoliaSearchKeys } from './SearchField.config';

import './SearchField.style.override';

// TODO: implement SearchOverlay
// export const SearchOverlay = SourceSearchOverlay;

export const searchClient = algoliasearch(`${algoliaSearchKeys().Application}`, `${algoliaSearchKeys().SearchOnlyApiKey}`, {
    // Caches Promises with the same request payload
    requestsCache: createInMemoryCache({ serializable: false })
});

export const indexName = `products_${window.storeConfig.code}`;

export const DebouncedSearchBox = connectSearchBox(SearchBox);

export const isB2B = JSON.parse(sessionStorage.getItem('is_b2b')) ?? false;

export const customer = JSON.parse(localStorage.getItem('customer', '{}'));

export const cookies = new Cookies();

// export const index = searchClient.initIndex('products_en_us');

/** @namespace Pwa/Component/SearchField/Component/SearchField */

export const LoadingIndicator = connectStateResults(({ isSearchStalled }) => (isSearchStalled
    ? (
<div className="LoaderWrapper">
    <div className="LoaderInner">
        <div className="LoaderImage">
            <Image
              alt="isPlaceholder"
              isPlaceholder
            />
        </div>
        <div className="LoaderText">
            <TextPlaceholder length="medium" />
            <TextPlaceholder length="short" />
        </div>
    </div>
    <div className="LoaderInner">
        <div className="LoaderImage">
            <Image
              alt="isPlaceholder"
              isPlaceholder
            />
        </div>
        <div className="LoaderText">
            <TextPlaceholder length="medium" />
            <TextPlaceholder length="short" />
        </div>
    </div>
</div>
    )
    : null
));

/** @namespace Pwa/Component/SearchField/Component/SearchField */
export class SearchField extends SourceSearchField {
    // TODO implement logic
    __construct(props) {
        super.__construct(props);

        this.state = {
            query: '',
            gaQuery: '',
            setTimeoutId: null,
            totalProducts: 0,
            hitsHash: null,
            queryId: null,
            objectId: null,
            searchResultsVisible: true
        };
    }

    componentDidMount() {
        searchInsights('init', {
            appId: `${algoliaSearchKeys().Application}`,
            apiKey: `${algoliaSearchKeys().SearchOnlyApiKey}`
        });
    }

    // shouldComponentUpdate(nextProps) {
    //     // Rendering the component only if
    //     // passed props value is changed

    //     if (nextProps !== this.props) {
    //         return true;
    //     }

    //     return false;
    // }

    onSearchEnterPress = (e) => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            history.push(appendWithStoreCode(`/search/${ search }`));
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
        }
    };

    onSearchResFun = () => {
        this.setState({ searchResultsVisible: false });
    };

    onSearchResShowFun = () => {
        this.setState({ searchResultsVisible: true });
    };

    searchClickEvent() {
        const { onSearchOutsideClick } = this.props;
        onSearchOutsideClick();
        scrollToTop();
    }

    handleAllResults() {
	    const { closeSearch, onSearchOutsideClick, hideActiveOverlay } = this.props;
        // onSearchOutsideClick();
	    const { value, query } = this.state;
	    history.push(`/search?q=${query}`);
        hideActiveOverlay();
        onSearchOutsideClick();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        this.closeSearch();
    }

    renderSearchResults() {
        const Results = connectStateResults(
            ({ searchState, searchResults, children }) => {
                if (isEmpty(searchState)) {
                    return null;
                }

                this.setState({
                    query: searchState.query,
                    totalProducts: searchResults.nbHits
                });

                return (
                    (
                        searchState && searchState.query === '' ? (
                            ('')
                        ) : (searchResults && searchResults.nbHits === 0 && searchResults.queryID) ? (
                            <div className="noResult">
                                { __('No results have been found for') }
                                { ' ' }
                                <strong>
                                { searchState.query }
                                </strong>
                                .
                                { /* <ClearRefinements /> */ }
                            </div>
                        ) : (children))
                );
            }
        );

        return (
            <div className="SearchField-Results">
                <div className="SearchField-Results-Container">
                    <div className="SearchField-Results-Inner">
                        <Results>
                            <div data-insights-index={ indexName }>
                                <Hits hitComponent={ ({ hit }) => this.renderHits(hit) } />
                            </div>
                            {/* <Pagination /> */}
                            {/* <Link
                            to={ `/search?q=${this.state.query}` }
                            className="SearchField-Results-Btn btn btn-primary"
                            onClick={ this.searchClickEvent.bind(this) }
                            >
                            { __(`Show all ${this.state.totalProducts} results`) }
                            </Link> */}
                           { (this.state.totalProducts > 9) && (
                                    <button
                                      type="submit"
                                      block="Button SearchField-Results-Btn"
                                      onClick={ this.handleAllResults.bind(this) }
                                      id="SearchField-Results-Btn"
                                    >
                                    { __('Show all %s results', this.state.totalProducts) }
                                    </button>
                           )}
                        </Results>
                    </div>
                    <LoadingIndicator />
                </div>
            </div>
        );
    }

    renderPrice(proPrice) {
        let cusPrice = removeCurrencySymbol(proPrice);
        const storeCurrency = window.storeConfig.currencySymbol;

        // Split according to price formatting
        if (['de_de', 'nl_be', 'es_be', 'fr_be', 'es_us'].includes(window.storeConfig.code)) {
            cusPrice = cusPrice.split(',');
        } else {
            cusPrice = cusPrice.split('.');
        }

        if (isUsaStore() || isAusStore() || isIntStore() || isEurStore()) {
            if (window.storeConfig.code === 'de_de') {
                return (
                <div className="price-amznstyle">
                    <div className="price-container" area-hidden="true">
                        <span className="price-whole">
                            { cusPrice[0] }
                        </span>
                        <span className="price-decimal">
                            { cusPrice[1] }
                        </span>
                        <span className="price-symbol">
                            { ' ' }
                            { storeCurrency }
                        </span>
                    </div>
                    <span className="offscreen">
                        { proPrice }
                    </span>
                </div>
                );
            }

            return (
                <div className="price-amznstyle">
                    <div className="price-container" area-hidden="true">
                        <span className="price-symbol">
                            { storeCurrency }
                        </span>
                        <span className="price-whole">
                            { cusPrice[0] }
                        </span>
                        <span className="price-decimal">
                            { cusPrice[1] }
                        </span>
                    </div>
                    <span className="offscreen">
                        { proPrice }
                    </span>
                </div>
            );
        }

        if (isRsaStore()) {
            const decimal = cusPrice[1];
            let decimalText = (
                <span className="price-decimal">
                    { cusPrice[1] }
                </span>
            );

            if (parseInt(decimal) === 0) {
                decimalText = null;
            }

            return (
                <div className="price-amznstyle">
                    <div className="price-container" area-hidden="true">
                        <span className="price-symbol">
                            { storeCurrency }
                        </span>
                        <span className="price-whole">
                            { cusPrice[0] }
                        </span>
                        { decimalText }
                    </div>
                    <span className="offscreen">
                        { proPrice }
                    </span>
                </div>
            );
        }

        return proPrice;
    }

    renderClickView(objectID, __position, __queryID) {
        const {
            customer = {}
        } = this.props;
        const { email } = customer;
        this.setState({ searchResultsVisible: false });
        scrollToTop();
        let userToken = '';
        if (isSignedIn() && email) {
            userToken = md5(email);
        } else {
            userToken = cookies.get('_ALGOLIA', '') || localStorage.getItem('unicUId', '');
            if (!userToken) {
                userToken = `anonymous-${uuidv4()}`;
                localStorage.setItem('unicUId', userToken);
            }
        }
        searchInsights('sendEvents', [
            {
                eventType: 'click',
                eventName: 'Clicked Product After Search',
                userToken,
                index: indexName,
                queryID: __queryID,
                objectIDs: [objectID],
                positions: [__position]
            }
        ]);
        searchInsights('sendEvents', [
            {
                eventType: 'view',
                eventName: 'Viewed Product',
                userToken,
                index: indexName,
                queryID: __queryID,
                objectIDs: [objectID]
            }
        ]);
    }

    renderHits(hit) {
        const {
            name, brand, url, price, visibility, thumbnail_url, image_url, type_id, objectID, __position, __queryID
        } = hit;

        if (isEmpty(hit)) {
            return null;
        }

        // if (__queryID === this.state.queryId) {
        //     return null
        // }

        this.setState({ queryId: __queryID, objectId: objectID });

        const { customer = {} } = this.props;
        const { is_b2b, group_id } = customer;

        let finalCustomerGroupId = 0;

        if (group_id) {
            finalCustomerGroupId = group_id;
        }

        // console.log('price', customer, price);
        // Find customer_group_id where = 0 in price array
        const priceIndex = price.findIndex((item) => item.customer_group_id === finalCustomerGroupId || item.customer_group_id === 0);

        const imgUrl = '/media/catalog/product';

        const { currencyCode } = window.storeConfig;

        const proPrice = formatPrice(price[priceIndex]?.min_price ?? 0, currencyCode);

        const renderFromText = () => {
            if (type_id === 'simple') {
                return null;
            }

            return (
                <div className="priceFrom">
                    { __('Price From:') }
                </div>
            );
        };

        let priceBoxText = 'incl. 19% DE VAT, excl. Shipping';
        if (window.storeConfig.code === 'de_de') {
            priceBoxText = 'inkl. 19% DE MwSt., zzgl. Versand';
        }

        return (
            <div
              className="SearchField-Item-Wrapp"
            >
                <Link
                  to={ `/${url}` }
                  className="SearchField-Item SearchField-Hit"
                  data-insights-object-id={ objectID }
                  data-insights-position={ __position }
                  data-insights-query-id={ __queryID }
                  onClick={ () => this.renderClickView(objectID, __position, __queryID) }
                >
                    <Image
                      block="SearchOverlay"
                      elem="Image"
                      src={ `${thumbnail_url}` }
                      alt={ name }
                      isPlaceholder={ !thumbnail_url }
                    />
                    <div className="SearchField-Item-Block">
                        <div className="SearchField-Title">
                            <Highlight
                              attribute="name"
                              hit={ hit }
                              nonHighlightedTagName="span"
                            />
                        </div>
                        <div className="SearchField-Additionals">
                            { (brand && !isEmpty(brand)) && (
                                <>
                                <span className="SearchField-Brand">
                                    { brand }
                                </span>
                                <span className="separator" />
                                </>
                            ) }
                            <span className="SearchField-Sku">
                                { __('SKU') }
                                :
                                { ' ' }
                                <Highlight
                                  attribute="sku"
                                  hit={ hit }
                                  nonHighlightedTagName="span"
                                />
                            </span>
                        </div>
                        { renderFromText() }
                        { this.renderPrice(proPrice) }
                        { isEurStore() && (
                            <div className="price-box-text">
                                { priceBoxText }
                            </div>
                        ) }
                    </div>
                </Link>
            </div>
        );
    }

    closeSearch = () => {
        const { onSearchOutsideClick } = this.props;

        onSearchOutsideClick();
    };

    renderSearchTypesense() {
        const { customer = {} } = this.props;
        const { searchResultsVisible } = this.state;
        const { is_b2b, email } = customer;

        // Check if B2B customer
        let visibilityValue = 4;
        if (is_b2b && isB2BUser(is_b2b)) {
            visibilityValue = 3;
        }

        // Visibility String
        let visibilityString = `visibility>=${visibilityValue}`;
        if (is_b2b && isB2BUser(is_b2b)) {
            visibilityString = `visibility>=${visibilityValue} AND b2c_product=0`;
        }

        let userToken = '';
        if (isSignedIn() && email) {
            userToken = md5(email);
        } else {
            userToken = cookies.get('_ALGOLIA', '') || localStorage.getItem('unicUId', '');
            if (!userToken) {
                userToken = `anonymous-${uuidv4()}`;
                localStorage.setItem('unicUId', userToken);
            }
        }

        // searchInsights('setUserToken', userToken);

        // console.log('yash', userToken);

        const handleSearchStateChange = ({ query }) => {
            // Don't send GA request multiple times if the same query is sent
            if (query && query !== this.state.gaQuery && query.length >= 2) {
                clearTimeout(this.state.timeoutId);
                this.setState({ gaQuery: query, gaQuerySending: true, timeoutId: null });

                const timeoutId = setTimeout(() => {
                    if (query) {
                        window.dataLayer = window.dataLayer || [];

                        // Url encode search term
                        const searchTermQuery = encodeURIComponent(query);

                        const modifiedLocation = {
                            ...location,
                            href: `${location.origin + window.storeConfig.baseName}search?q=${searchTermQuery}`,
                            search: `?q=${searchTermQuery}`
                        };

                        // Pageview event
                        window.dataLayer.push({
                            event: 'pageview',
                            pageTitle: 'Search | Front Runner',
                            url: modifiedLocation.href,
                            eventCategory: 'search',
                            eventAction: 'quickSearch',
                            eventLabel: 'query',
                            eventValue: query,
                            page: {
                                url: modifiedLocation,
                                title: 'Search | Front Runner'
                            }
                        });

                        // Search event
                        window.dataLayer.push({
                            event: 'search',
                            searchQuery: query
                        });

                        // Hits viewed event
                        window.dataLayer.push({
                            event: 'hits_viewed'
                        });

                        // searchInsights('sendEvents', [
                        //     {
                        //         eventType: 'view',
                        //         userToken,
                        //         eventName: 'Testing hits viewed',
                        //         index: indexName,
                        //         objectIDs: ['objectID-1', 'objectID-2'],
                        //         queryId: this.state.queryId
                        //     }
                        // ]);
                    }

                    this.setState({ gaQuerySending: false });
                }, 3000);

                this.setState({ timeoutId });
                this.setState({ gaQuery: query });
            }
        };

        // Conditional query, don't request query on init
        const conditionalQuery = {
            search(requests) {
                if (requests.every(({ params }) => !params.query) || requests.every(({ params }) => isEmpty(params.query))) {
                    return Promise.resolve({
                        results: requests.map(() => ({
                            hits: [],
                            nbHits: 0,
                            nbPages: 0,
                            page: 0,
                            processingTimeMS: 0,
                            hitsPerPage: 0,
                            exhaustiveNbHits: false,
                            query: '',
                            params: ''
                        }))
                    });
                }

                return searchClient.search(requests);
            }
        };

        const HitsViewed = connectHits(({ hits }) => {
            if (isEmpty(hits)) {
                return null;
            }

            const hash = md5(JSON.stringify(hits)).toString();
            const currentHash = this.state.hitsHash;
            const objectIdArray = hits.map((data) => data.objectID);
            const queryId = hits[0]?.__queryID;
            if (hash !== currentHash) {
                this.setState({ hitsHash: hash }, () => {
                    if (this.state.query !== '') {
                        searchInsights('sendEvents', [
                            {
                                eventType: 'view',
                                eventName: 'Search Hits Viewed',
                                userToken,
                                index: indexName,
                                queryID: queryId,
                                objectIDs: objectIdArray
                            }
                        ]);
                    }
                });
            }
            // if (hash !== currentHash && this.state.hitsHash !== null && this.state.query !== '') {
            //     console.log('1234555 PEWPEW send GA event', hash, this.state.hitsHash);

            //     searchInsights('sendEvents', [
            //         {
            //             eventType: 'view',
            //             userToken,
            //             eventName: 'Serach hits viewed',
            //             index: indexName,
            //             queryID: queryId,
            //             objectIDs: objectIdArray
            //         }
            //     ]);
            // }

            return null;
        });

        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapperTysens"
            >
                <InstantSearch
                  indexName={ indexName }
                  searchClient={ conditionalQuery }
                  onSearchStateChange={ handleSearchStateChange }
                >
                    <HitsViewed />
                    <Configure
                      filters={`${visibilityString}`}
                      hitsPerPage={10}
                      analytics={true}
                      clickAnalytics={true}
                      enablePersonalization={true}
                      userToken={userToken}
                      distinct
                    />

                    {/* <SearchBox placeholder="I'm looking for..." /> */}
                    <DebouncedSearchBox delay={ 500 } onSearchOutsideClick={ this.props.onSearchOutsideClick } onSearchBarFocus={ this.props.onSearchBarFocus } mods={ this.props.isActive } ref={ this.searchBarRef } closeSearch={ this.closeSearch } onSearchRes={ this.onSearchResFun } onSearchResShow={ this.onSearchResShowFun } />
                    { /* <LoadingIndicator /> */ }
                    { searchResultsVisible && this.renderSearchResults() }
                </InstantSearch>
            </div>
        );
    }

    renderSearch() {
        return (
            <div
              block="SearchField"
              elem="SearchInnerWrapper"
            >
                <div className="search-wrapper">
                    { /* <input
                      id="search-field"
                      ref={ this.searchBarRef }
                      block="SearchField"
                      elem="Input"
                      onFocus={ onSearchBarFocus }
                      mods={ { isActive } }
                      placeholder={ __("I'm looking for...") }
                      autoComplete="off"
                      aria-label={ __('Search') }
                    /> */ }
                    <FontAwesomeIcon icon={ faSearch } size="1x" color="#444" />
                    {/* <ClickOutside onClick={ this.closeSearch }> */}
                    <div block="SearchField" elem="Wrapper">
                    { this.renderSearchTypesense() }
                    </div>
                    {/* </ClickOutside> */}
                </div>
            </div>
        );
    }
}

export default SearchField;
